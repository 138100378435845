<template>
  <div>
    <div class="wrapper">
      <!-- section dashboard -->
      <section id="dashboard-owner" class="section-duke section-dark jpadding jpadding-20 duke-style">
        <div class="jtitle jtitle-80 text-center">
          <h3><small>DUKE</small><br />OWNER</h3>
          <div class="jsubtitle">
            <p id="p-text">
              <small
                ><strong>Welcome {{ user.firstName + " " + user.lastName }} to DUKE OWNER CLUB.</strong
                ><br />Here you have access to all your watch-related information, you can manage your watch
                services, warranty and appointments, and have a priority access to the news and
                novelties.</small
              >
            </p>
          </div>
        </div>

        <div class="grid-3">
          <router-link :to="{ name: 'MyWatches' }" class="jcard-duke">
            <i class="fal fa-watch fa-3x mb-3"></i>
            <h5>My Watches</h5>
            <!-- <p class="m-0"><small>OWNERS CLUB</small></p> -->
          </router-link>
          <router-link :to="{ name: 'Collection' }" class="jcard-duke">
            <i class="fal fa-shopping-cart fa-3x mb-3"></i>
            <h5>Shop</h5>
            <!-- <p class="m-0"><small>OWNERS CLUB</small></p> -->
          </router-link>
          <router-link :to="{ name: 'PaymentsOwner' }" class="jcard-duke">
            <i class="fal fa-credit-card fa-3x mb-3"></i>
            <h5>Payments</h5>
            <!-- <p class="m-0"><small>OWNERS CLUB</small></p> -->
          </router-link>
          <router-link :to="{ name: 'AppointmentsList' }" class="jcard-duke">
            <i class="fal fa-clock fa-3x mb-3"></i>
            <h5>Appointments</h5>
            <!-- <p class="m-0"><small>OWNERS CLUB</small></p> -->
          </router-link>
          <router-link :to="{ name: 'NewsOwner' }" class="jcard-duke">
            <i class="fal fa-newspaper fa-3x mb-3"></i>
            <h5>News</h5>
            <!-- <p class="m-0"><small>OWNERS CLUB</small></p> -->
          </router-link>
          <router-link :to="{ name: 'Contact' }" class="jcard-duke">
            <i class="fal fa-headset fa-3x mb-3"></i>
            <h5 class="mb-3">Contact</h5>
            <!-- <p><i class="m-0 fal fa-link"></i></p> -->
          </router-link>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import firebase from "@/firebase/firebaseInit";
import C from "@/const";
const auth = firebase.auth;
const db = firebase.db;

export default {
  name: "DashboardOwner",
  data() {
    return {
      //userId: firebase.auth().currentUser.uid,
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters.getUser;
      }
    }
  },
  beforeCreate() {
    //load user
    var userDocRef = db.collection(C.COLLECTION.USERS).doc(auth.currentUser.uid);
    userDocRef
      .get()
      .then(userDoc => {
        this.$store.commit("setUser", userDoc.data());
      })
      .catch(function(error) {
        console.log("Error getting USER document:", error);
      });
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/css/platform.scss";
</style>
